<template>
	<div class="share">
		<van-nav-bar
			title="分享"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		></van-nav-bar>
		
		<van-swipe @change="checkPage" class="content" :loop="false" indicator-color="#6883FB">
		  <van-swipe-item class="qjc-relative">
			  <div id="pCode">
				  <van-image
				  	width="100%"
				  	height="auto"
				  	:src="require('@/assets/images/agent_share_pay.png')"
					@load="getInfo"
				   ></van-image>
				   
				   <div class="oper">
					  <div>
						  <div class="code">
							<vue-qr 
								v-if="pCode"
								:logoSrc="require('@/assets/images/agent_miyinlogo.png')" 
								:text="pCode"
								:size="500"
								:margin="0"
								:logoScale=".25"
								:correctLevel="3"
								:callback="backCreate('pCode')"
							></vue-qr>
						  </div>
						  
						  <div class="code-text qjc-fts-30">
							優惠碼：<span>{{ pCodeText }}</span>
						  </div>
					  </div>
					  
					  <van-image
						width="3.7rem"
						height="auto"
						:src="require('@/assets/images/agent_share_fingerprint.png')"
					  ></van-image>
				  				  
				   </div>
				   
				  <div
					class="canvas-img qjc-absolute"
					ref="pCode"
				  >
					  
				  </div>
			  </div>
			  
		  </van-swipe-item>
		  
		  <van-swipe-item class="qjc-relative">
			  <div id="rCode">
				  <van-image
				  	width="100%"
				  	height="auto"
				  	:src="require('@/assets/images/agent_share_regist.png')"
				  ></van-image>
				  
				  <div class="oper">
					  <div>
						  <div class="code">
							<vue-qr 
								v-if="rCode"
								:logoSrc="require('@/assets/images/agent_miyinlogo.png')" 
								:text="rCode"
								:size="500"
								:margin="0"
								:logoScale=".25"
								:correctLevel="3"
								:callback="backCreate('rCode')"
							></vue-qr>
						  </div>
						  
						  <div class="code-text qjc-fts-30">
							邀請碼：<span>{{ rCodeText }}</span>
						  </div>
					  </div>
					  
					  <van-image
						width="3.7rem"
						height="auto"
						:src="require('@/assets/images/agent_share_fingerprint.png')"
					  ></van-image>
				  				  
				  </div>
			  </div>
			  
			  <div
				class="canvas-img qjc-absolute"
				ref="rCode"
			  >
			  					  
			  </div>
			  
		  </van-swipe-item>
		  
		</van-swipe>
		
		<van-button @click="save" class="save qjc-c-fff qjc-bg-primary">
			<span class="qjc-vertical-m">保存圖片</span>
			<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
		</van-button>
		
		<p class="tip">使用微信瀏覽器打開，長按圖片即可保存圖片到手機</p>
		
		<div v-show="show" class="zzc" style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;background-color: #fff;z-index: 9999999;">
			<van-loading style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" type="spinner" />
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Swipe, SwipeItem, Image, Button, Loading } from 'vant'
	Vue.use(NavBar)
	   .use(Swipe)
	   .use(SwipeItem)
	   .use(Image)
	   .use(Button)
	   .use(Loading);
	   
	import { agentInfo } from '@/js/axios'
	   
	import vueQr from 'vue-qr'
	import Clipboard from 'clipboard';
	import html2Canvas from 'html2canvas'
	
	export default{
		name: 'agentInvitationShare',
		data() {
			return {
				pCode: '',// 立减邀请码
				pCodeText: '',
				pCodeImage: '',
				
				rCode: '',// 邀请注册代理人邀请码
				rCodeText: '',
				rCodeImage: '',
				
				pageIndex: 0,// 默认第一页
				
				show: true
			}
		},
		components: {
			vueQr
		},
		mounted() {
			
		},
		methods: {
			// 图片加载后开始
			getInfo() {
				this.$nextTick(() => {
					agentInfo().then(res => {
						if(res.code == 200){
							// 存储用户信息
							var userInfo = res.data; 
					
							if(userInfo.inviteCode){
								// 邀请购买
								this.pCodeText = userInfo.inviteCode;
								
								this.pCode = location.origin + '/' + this.$router.resolve({
									path: '/home/buyYjqz',
									query: {
										discountCode: this.pCodeText
									}
								}).href;
								
								// 邀请注册
								this.rCodeText = userInfo.inviteCode;
								
								this.rCode = location.origin + '/' + this.$router.resolve({
									path: '/agent/login',
									query: {
										invitCode: this.rCodeText
									}
								}).href;
							}else{
								var that = this;
								Toast.fail({
									message: '您沒有購買疫境求真套餐，邀請碼功能暫時無法使用',
									getContainer: '.invitation',
									onClose: () => {
										that.$router.replace('/agent');
									}
								});
							}
					
						}else{
							Toast.fail(res.msg);
						}
					});
				})
			},
			
			// 生成图片用于保存扫码等  参数：id
			createImg(id) {
				this.$nextTick(() => {
					setTimeout(() => {
						// 生成图片行高上下居中校准
						let textCss = document.querySelectorAll('.code-text');
						textCss.forEach(item => {
							item.style.lineHeight = '0.5rem';
							item.style.paddingBottom = '0.12rem';
						});
						
						let dom = document.getElementById(id);
						
						// 生成图片时移动到顶部,否则生成的图片顶部会留白
						window.scroll(0,0);
						html2Canvas(dom, {
							useCORS: true,
							scrollY: 0,
							logging: false,
							windowWeight: dom.offsetWidth,
							windowWeight: dom.offsetHeight
						}).then(canvas => {
							let dataUrl = canvas.toDataURL("image/jpeg", 0.9);
							this[id+'Image'] = dataUrl;
							this.$refs[id].innerHTML = "<img class='save"+ id +" qjc-wid-100 qjc-hei-100' src='"+ dataUrl +"'/>";
							
							// 显示内容
							this.show = false;
						});
					}, 200);
				})
			},
			
			backCreate(id) {
				this.createImg(id);
			},
			
			checkPage(index) {
				this.pageIndex = index;
			},
			
			// 保存图片
			save() {
				if(this.pageIndex == 0){// pCode
					this.dataURIToBlob(this.pCodeImage, '立減HKD$75');
				}else if(this.pageIndex == 1){// rCode
					this.dataURIToBlob(this.rCodeImage, '全港招募令');
				}
			},
			
			dataURIToBlob(dataURI, fileName) {
			  var binStr = atob(dataURI.split(',')[1]),
			    len = binStr.length,
			    arr = new Uint8Array(len);
			
			  for (var i = 0; i < len; i++) {
			    arr[i] = binStr.charCodeAt(i);
			  }
			
			  var blob = new Blob([arr]);
			
			  var a = document.createElement('a');
			  a.download = fileName + '.jpg';
			  
			  a.href = URL.createObjectURL(blob);
			  
			  a.click();
			  URL.revokeObjectURL(blob);
			}
			
		}
	}
</script>

<style scoped>
	.content >>> .van-swipe__indicators{
		bottom: 0;
	}
	.content >>> .van-swipe__indicator{
		opacity: 1;
		background-color: #DADFF5;
		transition: all .5s;
	}
	.content >>> .van-swipe__indicator--active{
		width: 0.48rem;
		border-radius: 0.5rem;
	}
</style>
<style lang="scss" scoped>
	.share{
		.content{
			padding-bottom: 0.64rem;
			
			.van-swipe-item{
				padding: 0 0.45rem;
			}
			
			.oper{
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 0.36rem;
				
				.code{
					width: 2.8rem;
					height: 2.8rem;
					margin: 0.24rem;
					
					img{
						width: 100%;
						height: 100%;
					}
				}
				
				.code-text{
					display: inline-block;
					line-height: 0.56rem;
					background-color: #DADFF5;
					border-radius: 0.28rem;
					padding: 0 0.3rem;
				}
			}
			
			.canvas-img{
				top: 0;
				left: 0.45rem;
				right: 0.45rem;
				bottom: 0;
				
			}
			
		}
		
		.save{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 6.86rem;
			height: 0.8rem;
			line-height: 0.8rem;
			font-size: 0.32rem;
			border-radius: 0.08rem;
			border-color: #6883FB;
			margin: 0.24rem auto 0;
			
			img{
				width: 0.59rem;
				height: auto;
				margin-top: -0.1rem;
				margin-left: 0.1rem;
			}
		}
		
		.tip{
			color: #8C9699;
			line-height: 0.34rem;
			font-size: 0.26rem;
			margin: 0.16rem 0;
		}
		
	}
</style>
